@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
  color: #111827;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Typography improvements */
h1, h2, h3, h4, h5, h6 {
  letter-spacing: -0.025em;
}

/* Button hover effects */
button {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Image hover effects */
img {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Card hover effects */
.group:hover .group-hover\:shadow-md {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

/* Smooth transitions */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

/* Focus styles */
button:focus, a:focus {
  outline: 2px solid #3b82f6;
  outline-offset: 2px;
}

.Tune-logo {
  font-size: 2rem;
  font-weight: bold;
  background: linear-gradient(to right, #4285F4, #3367D6);
  -webkit-background-clip: text;
  color: transparent;
}
